import { getAuth, onAuthStateChanged } from '@firebase/auth';
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore'; 
import { getStorage } from 'firebase/storage';
import { useState, useEffect, useContext, createContext } from 'react';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);

// Create AuthContext
export const AuthContext = createContext();

// Initialize Cloud Firestore Reference
export const db = getFirestore(firebaseApp);

// Initialize Firebase Storage
export const storage = getStorage(firebaseApp);

// AuthContextProvider to manage auth state
export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState(null);  // User is initially null
  const [loading, setLoading] = useState(true); // Track loading state
  const [error, setError] = useState(null); // Handle possible errors

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(
      auth,
      (user) => {
        setUser(user);  // Set the user when auth state changes
        setLoading(false);  // No longer loading once we have user info
      },
      (error) => {
        setError(error);  // Handle error if any
        setLoading(false);  // No longer loading in case of error
      }
    );

    return () => unsubscribe();
  }, []);

  return (
    <AuthContext.Provider value={{ user, loading, error }}>
      {children}
    </AuthContext.Provider>
  );
};

// Custom hook to access auth state
export const useAuthState = () => {
  const auth = useContext(AuthContext);
  return { ...auth, isAuthenticated: auth.user != null };
};