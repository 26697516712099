import { useCallback } from 'react'
import { useTranslation } from 'react-i18next';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'
import { TextInput, PasswordInput, Button, Paper, Title, Text, Group, Stack, Center, Box, useMantineTheme} from '@mantine/core';
import logo from './../../assets/logo_lg.png';
import './../../App.css';

export const Login = () => {

    const { t } = useTranslation();
    const theme = useMantineTheme();

    const handleSubmit = useCallback(async e => {
        e.preventDefault()
        const { email, password } = e.target.elements
        const auth = getAuth()
        try {
            await signInWithEmailAndPassword(auth, email.value, password.value)
        } catch (e) {
            alert(e.message)
        }
    }, [])

    return (
        <Center style={{ height: '100vh', backgroundColor: theme.colors.nga[2] }}>
            <Box style={{ width: 400 }}>
                <Paper radius="md" p="xl" withBorder shadow="md">
                    <Center>
                        <img src={logo} alt="Logo" width={200} />
                    </Center>
                    <Title order={2} align="center" mt="md" mb="lg" sx={{
                        fontFamily: "'Merienda', cursive",
                    }}>
                        Adventskalendar 2024
                    </Title>
                    <form onSubmit={handleSubmit}>
                        <Stack spacing="md">
                            <TextInput
                                name="email"
                                label={t('login.email')}
                                placeholder="your-email@example.com"
                                type="email"
                                required
                            />
                            <PasswordInput
                                name="password"
                                label={t('login.password')}
                                placeholder="Your password"
                                required
                            />
                            <Group position="apart">
                                <Button type="submit" fullWidth style={{ backgroundColor: theme.colors.nga[0] }}>
                                    Los gehts!
                                </Button>
                            </Group>
                        </Stack>
                    </form>
                </Paper>
            </Box>
        </Center>
    );
}