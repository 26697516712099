import { Alert, Card, Divider, Grid, Modal, Group, Image, RingProgress, SimpleGrid, Text, Stack, Radio, Button, Title, useMantineTheme } from '@mantine/core';
import { useFetchAdvents } from './../../hooks/useFetchAdvents';
import { useDisclosure } from '@mantine/hooks';
import React, { useEffect, useState } from 'react';
import { useAuthState } from './../../firebase';
import { AdventItem } from './../../model/AdventItem';
import { IconInfoCircle } from '@tabler/icons-react';
import backgroundImage from './../../assets/santa.jpg';

export default function AdminMain() {

    const theme = useMantineTheme();
    const [opened, { open, close }] = useDisclosure(false);
    const currentDate = new Date();

    // Extract the day, month, and year as numbers
    const day = currentDate.getDate(); // Day of the month (1-31)
    const month = currentDate.getMonth() + 1; // Month (0-11, so +1 for 1-12)
    const year = currentDate.getFullYear(); // Full year (e.g., 2024)
    const progressValue = (day / 24) * 100

    const { user, loading, error } = useAuthState();
    const [timeRemaining, setTimeRemaining] = useState('');
    const [validationAttempt, setValidationAttempt] = useState(1);
    const adventItems = useFetchAdvents(user, validationAttempt);
    const [selectedAdvent, setSelectedAdvent] = useState<(AdventItem | null)>(null);
    const [showInvalidModal, setShowInvalidModal] = useState<boolean>(false);
    const [selectedAnswer, setSelectedAnswer] = useState("");
    const [showError, setShowError] = useState<boolean>(false);
    const [showSuccess, setShowSuccess] = useState<boolean>(false);

    useEffect(() => {
        const calculateTimeRemaining = () => {
            const now = new Date();
            const christmas = new Date(now.getFullYear(), 11, 25); // December 25 of the current year
            if (now.getMonth() === 11 && now.getDate() > 25) {
                christmas.setFullYear(christmas.getFullYear() + 1);
            }

            const diff = christmas.getTime() - now.getTime();
            const days = Math.floor(diff / (1000 * 60 * 60 * 24));
            const hours = Math.floor((diff / (1000 * 60 * 60)) % 24);
            const minutes = Math.floor((diff / (1000 * 60)) % 60);
            const seconds = Math.floor((diff / 1000) % 60);

            setTimeRemaining(
                `${days} Tage, ${hours} Stunden, ${minutes} Minuten, ${seconds} Sekunden`
            );
        };

        calculateTimeRemaining(); // Initial calculation
        const interval = setInterval(calculateTimeRemaining, 1000); // Update every second

        return () => clearInterval(interval); // Cleanup on component unmount
    }, []);

    const handleRapidClick = (advent: AdventItem) => {
        handleModalOpen(advent);
    };

    const handleModalOpen = (advent: AdventItem) => {
        if (day < advent.day) {
            setShowInvalidModal(true);
        } else {
            setSelectedAdvent(advent);
            setShowInvalidModal(false);
        }
        open();
    }

    const submitAnswer = () => {
        setShowError(false);
        setShowSuccess(false);
        if (selectedAdvent != null) {
            setShowSuccess(selectedAdvent.answer === selectedAnswer);
            setShowError(selectedAdvent.answer !== selectedAnswer);
        }
    };


    const advents = adventItems.map((advent) => (
        <Card
            key={advent.day}
            shadow="sm"
            padding="lg"
            radius="md"
            withBorder
            style={{
                backgroundImage: `url(${backgroundImage})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                aspectRatio: '1 / 1', // Make the card square
                display: 'flex', // Center the content
                alignItems: 'center',
                justifyContent: 'center',
            }}
            onClick={() => handleRapidClick(advent)}
        >
            <Text
                fw={700} // Make the text bold
                size="xl" // Adjust the size to a large title
                ta="center" // Correct way to center-align the text
                color={theme.colors.nga[0]} // Use white text for contrast
            >
                {advent.day}
            </Text>
        </Card>
    ));


    return (
        <>
            <Modal opened={opened} onClose={close} title="" >
                {showInvalidModal ? 
                    <>
                        <Image
                            radius="md"
                            src={require(`./../../assets/angry_santa.png`)}
                            alt={"angry"}
                        />
                    </>
                    :
                    <>
                       
                        {selectedAdvent && (
                            <Stack align="center">
                        
                                <Text fw={500}>{selectedAdvent?.description}</Text>
                                <Stack>
                                    <br />
                                    {selectedAdvent?.options.map((option, index) => (
                                        <Radio
                                            key={index} // Use a unique key for each option
                                            value={option} // Assign the value dynamically
                                            checked={selectedAnswer === option} // Check if the selected value matches
                                            color={theme.colors.nga[7]}
                                            onChange={() => {
                                                setSelectedAnswer(option);
                                            }}
                                            label={option} // Display the label dynamically
                                        />
                                    ))}
                                </Stack>
                                <br />
                                <br></br>
                                <Button onClick={() => {
                                    submitAnswer()
                                }} color={theme.colors.nga[3]} >
                                    Antworten!
                                </Button>
                                {showError ?
                                    <>
                                        <Alert variant="light" color="red" title="Jebote!"  icon={<IconInfoCircle />}>
                                            Das ist leider falsch! So kommt man nicht zu den Platzreife..
                                        </Alert>
                                    </>
                                    :
                                    <></>
                                }
                                {showSuccess ?
                                    <>
                                        <Alert variant="light" color="green" title="Herzlichen Glückwunsch! 🎉 Du hast die Frage richtig beantwortet!" icon={<IconInfoCircle />}>
                                            {selectedAdvent.gift}
                                        </Alert>
                                    </>
                                    :
                                    <></>
                                }
                            </Stack>
                        )}
                    </>
                }
            </Modal>
            <Grid>
                <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>

                    {month === 12 && year === 2024 ?
                        <>
                            <Card shadow="sm" padding="lg" radius="md" withBorder style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', height: '100%' }}>
                                <Card.Section withBorder inheritPadding py="xs">
                                    <Title style={{color: theme.colors.nga[3]}}>Adventzeit</Title>
                                    <RingProgress
                                        size={170}
                                        thickness={16}
                                        label={
                                            <Text size="xs" ta="center" px="xs" style={{ pointerEvents: 'none' }}>
                                                {day} / 24
                                            </Text>
                                        }
                                        sections={[
                                            { value: progressValue, color: theme.colors.nga[3], tooltip: 'Documents – 40 Gb' },
                                        ]}
                                    />
                                </Card.Section>
                                <Divider />
                                <br /><br />
                                <Title style={{ color: theme.colors.nga[1]}}>Weihnachts-Countdown</Title>
                                <br /><br />
                                <Text>{timeRemaining}</Text>
                                <br /><br />
                            </Card>
                        </>
                        : 
                        <></>
                    }

                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6, lg: 9 }}>

                    <Card shadow="sm" padding="lg" radius="md" withBorder>
                        <Card.Section withBorder inheritPadding py="xs">
                            <Alert variant="light" color="green" title="Santa's Golf-Adventskalender" icon={<IconInfoCircle />}>
                                In einem kleinen, verschneiten Dorf am Rande des Nordpols hatte Santa eine neue Idee, um die Vorfreude auf Weihnachten zu steigern. Er erschuf einen magischen Adventskalender, der nicht nur Süßigkeiten oder kleine Geschenke bereithielt, sondern auch eine besondere Herausforderung: Jeden Tag öffnete sich ein neues Türchen, hinter dem Santa eine Golf-Frage stellte. Die Elfen hatten ihm geholfen, die Fragen aus allen Bereichen des Spiels zu sammeln – von Regelkunde bis zu kniffligen Golf-Trivia. Nur wer die Frage richtig beantwortete, konnte das Geschenk des Tages freischalten! Mit seinem verschmitzten Lächeln forderte Santa jeden Morgen die Bewohner des Dorfes und alle Golf-Fans auf: "Zeigt mir eure Leidenschaft für das Spiel und eure Cleverness – dann wartet hinter jedem Türchen eine wundervolle Überraschung!"
                            </Alert>
                            <br />
                            <SimpleGrid cols={{ base: 1, sm: 1, md: 2, lg: 4, xl: 6 }}>
                                {advents}
                            </SimpleGrid>
                         
                            <br></br>

                        </Card.Section>
                    </Card>

                </Grid.Col>

                
            </Grid>
        </>
    );
}

