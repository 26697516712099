import {
    BrowserRouter as Router,
    Routes,
    Route,
    Navigate
} from 'react-router-dom';
import { useEffect } from 'react';
import { AdminX } from './pages/Admin/AdminX';
import { Register } from './pages/Register/Register';
import { Login } from './pages/Login/Login';
import { ForgotPassword } from './pages/Login/ForgotPassword';
import { AuthContextProvider, useAuthState } from './firebase';
import { MantineColorsTuple, createTheme, MantineProvider, Loader } from '@mantine/core';
import '@mantine/core/styles.css';


const brand: MantineColorsTuple = [
    '#e6ffee',
    '#d3f9e0',
    '#a8f2c0',
    '#7aea9f',
    '#54e382',
    '#3bdf70',
    '#2bdd66',
    '#1bc455',
    '#0bae4a',
    '#00973c'
];

const brandAccent: MantineColorsTuple = [
    "#f0f4fa",
    "#dfe5ed",
    "#bbc8dd",
    "#94aacd",
    "#7490c0",
    "#5f80b8",
    "#5478b5",
    "#4467a0",
    "#3b5b90",
    "#2e4f80"
]

const nga: MantineColorsTuple = [
  "#FF0000",
  "#FFA27F",
  "#FFE8C5",
  "#97BE5A",
  "#FF0000",
  "#FFA27F",
  "#FFE8C5",
  "#97BE5A",
  "#00372a",
  "#00372a",
]



const theme = createTheme({
    colors: {
        brand,
        brandAccent,
        nga
    },
    fontFamily: "'Merienda', cursive",
});

// AuthenticatedRoute using React Router v6
const AuthenticatedRoute = ({ element: Component, ...props }) => {
    const { isAuthenticated, loading} = useAuthState();  // Get loading state
    // Show loading screen until authentication status is determined
    if (loading) {
        return <Loader color="green" />;
    }

    // If authenticated, render the component, else redirect to login
    return isAuthenticated ? <Component {...props} /> : <Navigate to="/login" />;
};

// UnauthenticatedRoute using React Router v6
const UnauthenticatedRoute = ({ element: Component, ...props }) => {
    const { isAuthenticated, loading } = useAuthState();  // Get loading state
    // Show loading screen until authentication status is determined
    if (loading) {
        return <div>Loading...</div>;
    }

    // If not authenticated, render the component, else redirect to home
    return !isAuthenticated ? <Component {...props} /> : <Navigate to="/" />;
};

function App() {

    useEffect(() => {
        document.title = "Advents";  // Set the new title here
    }, []);

    return (
        <MantineProvider
            theme={theme}
            defaultColorScheme="light"
            withGlobalStyles
            withNormalizeCss        >
            <AuthContextProvider>
                <Router>
                    <Routes>
                        <Route
                            path="/"
                            element={<AuthenticatedRoute element={AdminX} />}
                        />
                        <Route
                            path="/register"
                            element={<UnauthenticatedRoute element={Register} />}
                        />
                        <Route
                            path="/login"
                            element={<UnauthenticatedRoute element={Login} />}
                        />
                        <Route
                            path="/reset-password"
                            element={<UnauthenticatedRoute element={ForgotPassword} />}
                        />
                    </Routes>
                </Router>
            </AuthContextProvider>
        </MantineProvider>
    );
}

export default App;

