//Hooks
import { useState, useEffect } from 'react';

//i18n
import { useTranslation } from 'react-i18next';

//Firebase
import { getAuth, signOut } from '@firebase/auth';
import { useAuthState, db } from './../../firebase';
import { doc, updateDoc } from "firebase/firestore";


//Mantine UI Elements
import { AppShell, Group, Button, Loader, useMantineTheme } from '@mantine/core';


//Components
import AdminMain from './AdminMain';

// Icons
import logo from './../../assets/logo_sm.png';
import { IconLogout } from '@tabler/icons-react';


export function AdminX() {

    const theme = useMantineTheme();
    const { t, i18n } = useTranslation();
    const { user, loading, error } = useAuthState();

    if (loading) {
        return <Loader color="green" />;
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    return (
        <AppShell
            header={{ height: 60 }}
            padding="md">
            <AppShell.Header style={{ backgroundColor: '#fff', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Group h="100%" px="md">
                    <img src={logo} alt="Logo" height={36} />
                </Group>
                <Group>
                    <Button
                        leftSection={<IconLogout size={14} />}
                        style={{ backgroundColor: theme.colors.nga[4], color: 'white', marginRight: 10 }}
                        onClick={() => signOut(getAuth())}
                    >
                        Abmelden
                    </Button>
                </Group>
            </AppShell.Header>
            <AppShell.Main style={{ backgroundColor: "#f8f9fa" }}>
                <AdminMain />
            </AppShell.Main>
        </AppShell>
    );
}